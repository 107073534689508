import React from "react"
import {Router , Route} from "@reach/router"
import Contact from "../pages/contact"
import Services from "../pages/services"
import Commercial from "../pages/commercial"
import Faq from "../pages/faq"
import Team from "../pages/team"
import Home from "../pages/index"
import Testimonials from "../pages/testimonials"

import Landscaping from "./services/landscaping"
import Electrical from "../pages/services/electrical"
import Painting from "../pages/services/painting"
import CustomPieces from "./services/custompieces"
import Hydro from "../pages/services/hydro"
import SewerCleaning from "../pages/services/sewer-cleaning"
import SewerTracing from "../pages/services/sewer-line-tracing"
import SewerRepair from "../pages/services/sewer-repair"
import Clothing from "../pages/services/clothing"
import SewerandPlumbing from "./services/sewerandplumbing"
import SeasonalandPermanent from "../pages/services/seasonalandpermanent"
const IndexPage = () => (
    
      <Router>
        <Home exact path="/" />
        <Contact path="contact" />
        <Services path="services" />
        <Team path="team" />
        <Faq path="faq" />
        <Testimonials path="testimonials" />

        <CustomPieces path="custompieces" />
        <Clothing path="clothing" />
        <Electrical path="electrical" />
        <Painting path="painting" />
        <SewerandPlumbing path="sewerandplumbing" />
        <SeasonalandPermanent path="seasonalandpermanent" />
        <Landscaping path="/services/landscaping" />
      </Router>
  
  )

  export default IndexPage
